import React from "react";
function Pricing(props) {
    return(
        <section id="pricing" class="pricing">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>{props.dataList.gpsPlan.title}</h2>
            <p>{props.dataList.gpsPlan.titleParagraph}</p>
          </div>
          <div class="row">
          {props.dataList.gpsPlan.planList.map(data =>
            <div class={props.dataList.gpsPlan.colSize} data-aos="zoom-im" data-aos-delay="100">
              <div class="box featured">
                <h6 style={{marginBottom:30,fontSize:25,color:'white'}}>{data.planTitle}</h6>
                <h5 style={{marginBottom:30,}}>
                  {data.discount!=="0" &&
                  (
                    <>
                    <sup style={{fontSize:18,color:'black',textDecoration:'line-through'}}>Rs. {data.price}/-</sup>
                    <span style={{fontSize:25,color:'white',fontWeight:'bold'}}>{Math.round(Number(data.price)-(Number(data.price)*Number(data.discount)/100))}/-</span>
                    </>
                  )
                  }{data.discount==="0" &&
                   (<>
                    <sup style={{fontSize:25,color:'white',fontWeight:'bold'}}>Rs. {data.price}/-</sup>
                  
                    </>) 
                  }
                </h5>
                <h6 style={{color:'white'}}><span>({data.planType})</span></h6>
                <ul>
                {data.item.map(item =>
                  <li><i className="bx bx-check-double"></i>{item.value}</li>
                )}
                </ul>
                <div class="btn-wrap">
                  <a href={props.dataList.gpsPlan.btnLink} style={{}} class="btn-buy">{props.dataList.gpsPlan.btnTitle}</a>
                </div>
              </div>
            </div>
             )}
          </div>
  
        </div>
      </section>
    );
}
export default Pricing;
import React, { useState } from "react";
import axios from "axios";
import globalStorage from '../global-storage';
import Pricing from "../component/pricing";
function ProductAndPriciing(params) {
  return (
    <>
      <section id="Product">
      <img src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/headerImage.png" style={{width:"100%"}}></img>
      </section>
      <Pricing dataList={globalStorage.priceSubscription}/>
      <Pricing dataList={globalStorage.priceGps}/>
      <Pricing dataList={globalStorage.renewGpsPlan}/>
    </>
  );
}
export default ProductAndPriciing;
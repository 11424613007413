import React, { useState } from "react";
import axios from "axios";
import globalStorage from '../../global-storage';
import AboutLayout from "../../component/about-layout"
function AvailableTruck(params) {
  return (
    <>
      <section id="headerImage">
      <img src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/header/available-truck-header-min.png" style={{width:"100%"}}></img>
      </section>
      <AboutLayout appAbout={globalStorage.appAbout.aboutAvailableTruck} classStyle={"about-video"}/>
    </>
  );
}
export default AvailableTruck;
import React from "react";
import globalStorage from '../global-storage';
function About(props) {
  return (
    <>
      <section id="aboutSection" style={{ marginTop: 1 }}>
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>{globalStorage.aboutPage.title}</h2>
            <p>{globalStorage.aboutPage.subTitle}</p>
          </div>
          <div class="row content">
            <div class="col-lg-12">
              <p style={{ textAlign: 'justify' }}>
              <strong>MoversTrip</strong> is newly fast growing online platform for Transportation Business. MoversTrip is a name of much efforts and dedication. We facilitate 24X7 ongoing business opportunity for Truck Owners & Transport Business. We are bringing a revolution in the Transport Industry and creating a platform where Truck Owner can get the <strong>Load & Transporter</strong> can get the Truck easily. It is so simple like that.
              </p>
              <h6>MoversTrip has 3 types of Product Subscriptions</h6>
              <ul>
                <li>MoversTrip Subscription </li>
                <li>MTGPS Product</li>
                <li>MTGPS Renewal Subscription</li>
              </ul>
              <p style={{ textAlign: 'justify',fontSize:25}}>MoversTrip Subscription has further 4 types of pocket friendly categories.</p>
              <table className='table'>
                <tr>
                  <th>A.</th>
                  <th>MoversTrip Monthly Subscription </th>
                  <td>Rs. 99</td>
                </tr>
                <tr>
                <th>B.</th>
                  <th>MoversTrip Quarterly Subscription</th>
                  <td>Rs. 267 </td>
                </tr>
                <tr>
                <th>C.</th>
                  <th>MoversTrip Half Yearly Subscription</th>
                  <td>Rs. 475</td>
                </tr>
                <tr>
                <th>D.</th>
                  <th>MoversTrip Annual Subscription </th>
                  <td>Rs. 832 </td>
                </tr>
              </table>
              <p style={{textAlign:'justify'}}>We are providing services of Available Truck, Available Load, Load Request, Post Load, Rate Card, Largest Transport Digital Directory of India and the Directory for Packers & Movers. Where, we do fulfil most of the needs in Transport Business.</p>
              <p style={{textAlign:'justify'}}>MoversTrip’s Second Product is MTGPS, it is a most economical and fully loaded features GPS in the market. We charge just Rs. 2388 annually. Whereas, similar product is being charged approximately Rs. 3600 annually by others.  So, avail this service to ensure your vehicles safety, security and business assurance. </p>
              
              <p style={{textAlign:'justify',fontWeight:'bold'}}>MoversTrip’s third subscription is MTGPS Renewal, it’s a user friendly and pocket friendly, too. We honour our customer relationship and we make sure that smile should be there on our customer’s face. Based on easy going way we categorised 4 types:
</p>
<table className='table'>
                <tr>
                  <th>A.</th>
                  <th>MTGPS Monthly Renewal Subscription</th>
                  <td>Rs. 199</td>
                </tr>
                <tr>
                <th>B.</th>
                  <th>MTGPS Quarterly Renewal Subscription</th>
                  <td>Rs. 537</td>
                </tr>
                <tr>
                <th>C.</th>
                  <th>MTGPS Half Yearly Renewal Subscription</th>
                  <td>Rs. 955</td>
                </tr>
                <tr>
                <th>D.</th>
                  <th>MTGPS Annual Renewal Subscription</th>
                  <td>Rs. 1672</td>
                </tr>
              </table>
              <h2>MoversTrip Product & Subscriptions</h2>
              <hr/>
              <p style={{textAlign:'justify'}}>MoversTrip Product & Subscriptions are really fully loaded features of security and precise location for your vehicle. You have the full control over your vehicle, no matter it’s far away from you, sitting at home or may be from your office or roaming anywhere in India, you can check the vehicle Running Status, Vehicle Stop Time Alert, Geo-Fencing, PAN India Network feature, Anti-Theft and loads of available features give you worth to own it. 
</p>

              <p style={{textAlign:'center',fontSize:18}}>Are you far away from home and your Truck is waiting for business?</p>
              <p style={{textAlign:'center',fontSize:20,color:"red"}}>OR</p>
              <p style={{textAlign:'center',fontSize:18}}>Are you waiting for a Truck for your available Loads?</p>
              <p style={{textAlign:'center',fontSize:20,color:"red"}}>OR</p>
              <p style={{textAlign:'center',fontSize:18}}>Sitting at home and thinking of getting the business for your Truck!!</p>
              <p style={{textAlign:'center',fontSize:20,color:"red"}}>OR</p>
              <p style={{textAlign:'center',fontSize:18}}>Worrying of your Truck location and no control over the Truck because you are not near! </p>
              <p style={{textAlign:'center',fontSize:20,color:"red"}}>OR</p>
              
              <h2>Worry not…..MoversTrip is here only for you!
</h2><hr/>
<p style={{textAlign:'justify'}}><strong>MoversTrip Subscription</strong> gives you a <strong>Digital Rate Card</strong> option where you can find the <strong>Estimated Transportation</strong> Cost in just a click. No time for waiting, just grow your business.
</p>
<p style={{textAlign:'justify'}}><strong>MoversTrip Subscription</strong> gives you an India’s largest <strong>Digital Transport Directory</strong>. Where you can find loads of information and opportunity to grow your business on your fingertips. We are first one who created such a large scale of information platform in India. It’s definitely fulfil all your business needs. 
</p>
<p style={{textAlign:'justify'}}>MoversTrip Users can get the all details of Transporters in a single click and contact them by call or WhatsApp. Even these business information can be shared to anyone at any point of time. </p>
<p style={{textAlign:'justify'}}>Our moto is earning happiness for you by giving multiple ways to multiply your business growths. If you stuck anywhere, just start availing our services. We can’t leave your behind…we are companion of your success. 
</p>
<p style={{textAlign:'justify'}}>We are committed to give you the best and money can’t be a reason to stop us coming closer. So, let us give you a glance of our MoversTrip subscription.
</p>
<p style={{textAlign:'justify'}}>Here, just in Rs. 99 you can avail our most the valuable services & features i.e. Digital Directory, Digital Rate Card, Available Trucks, Available Load etc. Rest of all, you can view all the commercial movement absolutely free.</p>
<p style={{textAlign:'center',fontSize:25}}>So, Don’t waste you precious time….Go to <strong style={{color:"green"}} >Google play and download the MoversTrip App</strong> immediately. </p>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
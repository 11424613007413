import './App.css';
import React from "react";
import { Routes, Route, Link,BrowserRouter } from "react-router-dom"
import Home2 from "../src/page/home";
import TransporterDirectory from './page/service/transporter-directory';
import PackersAndMoversDirectory from './page/service/packers-and-movers-directory';
import AvailableLoad from './page/service/available-load'
import AvailableTruck from './page/service/avaiable-truck'
import RateCard from './page/service/rate-card'
import HeaderNav from '../src/component/header';
import Footer from '../src/component/footer';
import GpsService from './page/service/gps-service';
import AboutUs from './page/about-us';
import Franchise from './page/franchise';
import Advertisement from './page/advertisement';
import ProductAndPricing from './page/product-and-pricing';
function App(props) {
  return (
  <main id="main">
    <HeaderNav/>
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="transporter-directory" element={<TransporterDirectory />} />
        <Route path="packers-and-movers-directory" element={<PackersAndMoversDirectory />} />
        <Route path="available-load" element={<AvailableLoad />} />
        <Route path="available-truck" element={<AvailableTruck />} />
        <Route path="rate-card" element={<RateCard />} />
        <Route path="franchise" element={<Franchise />} />
        <Route path="advertisement" element={<Advertisement />} />
        <Route path="gps-service" element={<GpsService />} />
        <Route path="product-and-pricing" element={<ProductAndPricing/>} />
      </Routes>
      <Footer/>
    </main>
  );
}
export default App;

import React, { useState } from "react";
import axios from "axios";
import globalStorage from '../global-storage';
import About from "../component/about"
function AboutUs(params) {
  return (
    <>
      <section id="headerImage">
      <img src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/headerImage.png" style={{width:"100%"}}></img>
      </section>
      <About/>
    </>
  );
}
export default AboutUs;
import logoImage from '../img/logo.png';
import React from "react";
import PlayStore from '../img/Google_play_icon.png';
//import app from '../App.css';
import { Link } from "react-router-dom";
import globalStorage from '../global-storage';
function HeaderNav() {
  return (
    <header id="header" class="fixed-top">
      <div className="container d-flex align-items-center" style={{maxWidth:1440}}>
        <h1 className="mr-auto"><a href="/"><img src={logoImage} className="img-fluid" style={{ width: 250 }} alt="logo" /></a></h1>
        <div className="nav-menu d-none d-lg-block">
          <ul>
            <li className="nav-item active"><Link to="/">Home</Link></li>
            <li className="nav-item"><Link to="/about-us">About us</Link></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" style={{ marginTop: -10 }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                Services
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link class="dropdown-item" to="/transporter-directory">
                  <span>Transport directory</span>
                  <p>Find all india verified transporter list</p>
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/packers-and-movers-directory">
                  <span>Packers and movers</span>
                  <p>Find all india verified Packers and movers list</p>
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/available-load">
                  <span>Available Load</span>
                  <p>List of Available load all india</p>
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/available-truck">
                  <span>Available Truck</span>
                  <p>Find near available truck in all India</p>
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/rate-card">
                  <span>Rate Card</span>
                  <p>Calculate transporation charges all india</p>
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/gps-service">
                  <span>Vehicle GPS Service</span>
                  <p>Request for GPS and secoure your vehicle</p>
                </Link>
              </div>
            </li>
            <li className="nav-item"><Link to='/franchise'>Franchise</Link></li>
            <li className="nav-item"><Link to='/advertisement'>Advertisement</Link></li>
            <li className="nav-item"><Link to='/product-and-pricing'>Product & Pricing</Link></li>
            <li className="nav-item"><Link to="/#contact">Contact</Link></li>

            <li className='nav-item'><Link to={globalStorage.appurl.mtGpsUrl}><img style={{ width: 100 }} src={PlayStore} alt="PayStore Icon" /></Link></li>
          </ul>
        </div>

      </div>
    </header>
  );
}

export default HeaderNav;

import '../App.css';
import React,{ useState } from "react";
import HeroSection from '../component/hero-section';
import About from '../component/about';
import CountSection from '../component/count-section';
import AboutVedio from '../component/about-vedio';
import Testimonials from '../component/testimonials'
import Service from '../component/service';
import ContactUs from "../component/contact-us";
import Slider from "../component/slider";
import Fetures from "../component/feture";
import YoutubeSection from "../component/youTubeSection"
function Home(props) {
  return (
  <main id="main">
    <Slider/>
      <Fetures/>
      <AboutVedio/>
      <CountSection />
      <Testimonials/>
      <YoutubeSection/>
      <Service/>
      <ContactUs/>
    </main>
  );
}
export default Home;

import globalStorage from '../global-storage';
import React from "react";
function Fetures(props) {
    return (
        <>
            <section id="fetures" style={{ marginTop: 0, padding: 50 }}>
                <div class="section-title">
                    <h1>{globalStorage.moverstripFetures.title}</h1>
                    <p>{globalStorage.moverstripFetures.subTitle}</p>
                </div>
                <div className='row'>
                    {globalStorage.moverstripFetures.serviceCat.map(item =>
                        <div className='col-xs-6 col-sm-3 col-md-3 mt-2'>
                             <a href='/#'>
                            <div class="card" style={{ width: "100%" }}>
                                <img src={item.Image} style={{ width: "30%", marginTop: 30, margin: '0 auto' }} class="card-img-top" alt={item.title} />
                                <div class="card-body center">
                                    <h6 style={{textAlign:'center',}}>{item.title}</h6>
                                </div>
                            </div>
                            </a>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
export default Fetures;
import React from "react";
import globalStorage from '../global-storage';
function YoutubeSection(props) {
    return (
        <>
          <div class="dropdown-divider"></div>
            <section id="youtubeSection" style={{ marginTop: 0, padding: 50,}}>
                <div class="section-title">
                    <h1>{globalStorage.youTubeSection.title}</h1>
                </div>
                <div className='row'>
                    {globalStorage.youTubeSection.item.map(item =>
                        <div className='col-xs-6 col-sm-3 col-md-3 mt-2'>
                             <a href='/#'>
                            <div class="card" style={{ width: "100%" }}>
                            <iframe width="100%" height="250" src={item.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </a>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
export default YoutubeSection;
import PlayStore from '../img/Google_play_icon.png';
import React from "react";
import globalStorage from '../global-storage';
import { Link } from "react-router-dom";
import logoImage from '../img/white-logo.png';
function Footer(params) {
    return (
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 footer-contact">
                            <img src={logoImage} className="img-fluid" style={{ width: 250 }} alt="logo" />
                            <h6 style={{ fontSize: 14, marginTop: 10 }}>{globalStorage.contactUs.companyName}</h6>
                            <p style={{marginBottom:10}}><strong>Head Office.: </strong>{globalStorage.contactUs.headOfficeAddress}</p>
                            <p style={{marginBottom:10}}><strong>Register Office.: </strong>{globalStorage.contactUs.registerAddress}</p>
                            <p ><strong> Operational Contact Details.: </strong></p>
                            <p><strong>Address:</strong>{globalStorage.contactUs.operationalPerson}</p>
                            <p><strong>Email:</strong> info@moverstrip.com</p>
                            <p><strong>Contact Number: </strong>{globalStorage.contactUs.supportNumber}<br /></p>
                            <p> <strong>Help Line No.: </strong>{globalStorage.contactUs.helpNumber}<br /></p>
                            <p> <strong>Email: </strong>{globalStorage.contactUs.email}<br /></p>

                        </div>
                        <div class="col-lg-2 col-md-4 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/about-us">About Us</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/transporter-directory">Services</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="#contact">Contact Us</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/Faq">FAQ</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/product-and-pricing">Product & Pricing</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="Privacy-Policy.html">Privacy Policy</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="term-and-condition.html">Terms and Conditions</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="shipping-and-delivery-policy.html">Shipping and Delivery Policy</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="refund-and-cancellation-policy.html">Refund & Cancellation Policy</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/need-load">Load Request</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/available-truck">Available Truck</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/available-load">Available Load</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link to="/transporter-directory">Transport & Packers Movers Directory</Link></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-4 footer-newsletter">
                            <a href={globalStorage.appurl.moverstripAppUrl}><img src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/footerImage.png" style={{ width: "100%", paddingTop: 0,marginTop:80, }} alt="PayStore Icon" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container d-md-flex py-4">
                <div class="mr-md-auto text-center text-md-left">
                    <div class="copyright">
                        &copy; 2020 MOVERSTRIP DIGITAL SERVICES LLP. Designed By <strong><span>MOVERSTRIP DIGITAL SERVICES LLP</span></strong>. All Rights Reserved
                    </div>
                    Made with Love (❤️) in India
                </div>
                <div class="social-links text-center text-md-right pt-3 pt-md-0">
                    <a href={globalStorage.socialUrl.twitterUrl} class="twitter"><i class="bx bxl-twitter"></i></a>
                    <a href={globalStorage.socialUrl.facebookUrl} class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href={globalStorage.socialUrl.instagramUrl} class="instagram"><i class="bx bxl-instagram"></i></a>
                    <a href={globalStorage.socialUrl.linkedinUrl} class="linkedin"><i class="bx bxl-linkedin"></i></a>
                </div>
            </div>
        </footer>
    );
}
export default Footer
import React, { useState } from "react";
function AboutLayout(props) {
    return (
        <>
            <section id="about" className={props.classStyle}>
                <div className="container" data-aos="fade-up">
                    <div className="row">
                      
                        <div className="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                            <h3>{props.appAbout.title}</h3>
                            <p className="font-italic">
                                {props.appAbout.subTitle}
                            </p>
                            <ul>
                                {props.appAbout.item.map(item =>
                                    <li><i className="bx bx-check-double"></i>{item.value}</li>
                                )}
                            </ul>
                            <p>
                                {props.appAbout.details}
                            </p>
                        </div>
                        <div className="col-lg-6 video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
                            <img src={props.appAbout.imageUrl} style={{width:400}} alt="Mt Gps Tracker App Screen gif" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default AboutLayout;
/* eslint-disable jsx-a11y/iframe-has-title */
import React,{ useState } from "react";
import axios from "axios";
import globalStorage from "../global-storage";
function EnquiryForm(props) {
  const contactData=[];
  const [name,setName]=useState("");
  const [email,setEmail]=useState("");
  const [subject,setSubject]=useState("");
  const [message,setMessage]=useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [successMsg,setsuccessMsg]=useState(false);
  const handleSubmit = (evt) => {
    evt.preventDefault();
    contactData.push({
      contactName:name,
      emailId:email,
      subject:subject,
      message:message,
      contactNumber:contactNumber,
      contactType:props.type,
      status:false
    });
   // setContactValue(contactData);
    console.log(contactData)
    axios.post(`${globalStorage.apiUrl}MtContactUs`, contactData)
    .then((response) => {
      console.log(response);
      setsuccessMsg(true);
      props.successStatus(true);
      setName("");setEmail("");setSubject("");setMessage("");setContactNumber("");
    }, (error) => {
      console.log(error);
    });
    //alert(`Json:-${contactValue}`)
}
    return(
        <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>{props.title}</h2>
            <p>{props.subTitle}</p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 mt-5 mt-lg-0">
            <form onSubmit={handleSubmit}>
              <div className="php-email-form">
                <div className="form-row">
                  <div className="col-md-6 form-group">
                    <input type="text" className="form-control" value={name} onChange={e=>setName(e.target.value)} placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                    <div className="validate"></div>
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="email" className="form-control" value={email} onChange={e=>setEmail(e.target.value)} placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                 </div>
                 <div className="col-md-6 form-group">
                        <input type="number" className="form-control" value={contactNumber} onChange={e => setContactNumber(e.target.value)} placeholder="Your Contact Number" data-rule="minlen:4" data-msg="Please enter contact number" />
                        <div className="validate"></div>
                      </div>
                      <div className="col-md-6 form-group">
                  <input type="text" className="form-control" value={subject} onChange={e=>setSubject(e.target.value)} placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                  <div className="validate"></div>
                </div>
                </div>
               
                <div className="form-group">
                  <textarea className="form-control" value={message} onChange={e=>setMessage(e.target.value)} rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Location"></textarea>
                  <div className="validate"></div>
                </div>
                <div className="mb-3">
                {successMsg &&
                   <div class="alert alert-success" role="alert">
                   Your Request Send Successfully.
                  </div>
                }
                </div>
                <div className="text-center"><button type="submit">Send Request</button></div>
              </div>
              </form>
            </div>

          </div>
  
        </div>
      </section>      
    );
}
export default EnquiryForm;
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import axios from "axios";
import globalStorage from "../global-storage";
function CountSection(props) {
    const countValue={
        subscriber:1312,
        registerTruck:100,
        availableTruck:6,
        postLoads:4,
        transporter:8300,
        packersandmovers:1000
    }
    return (
        <>
            <section id="counts" class="counts section-bg">
                <div class="container">
                    <div class="row justify-content-end">
                    <div class="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div class="count-box">
                            <img src='https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/trasnporter.png' style={{ width: "40%", marginTop: 30, margin: '0 auto' }} class="card-img-top" />
                       
                                <span data-toggle="counter-up">{countValue.transporter}</span>
                                <p>Transporter Directory</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div class="count-box">
                            <img src='https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/cargo-mover.png' style={{ width: "40%", marginTop: 30, margin: '0 auto' }} class="card-img-top" />
                       
                                <span data-toggle="counter-up">{countValue.packersandmovers}</span>
                                <p>Packers And Movers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                
                            <div class="count-box">
                            <img src='https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/add-user.png' style={{ width: "40%", marginTop: 30, margin: '0 auto' }} class="card-img-top" />
                       
                                <span data-toggle="counter-up">{countValue.subscriber}</span>
                                <p>Subscriber</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                            
                        <div class="count-box">
                            <img src='https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/gps.png' style={{ width: "40%", marginTop: 30, margin: '0 auto' }} class="card-img-top" />
                       
                                <span data-toggle="counter-up">{countValue.registerTruck}</span>
                                <p>Registered GPS Truck</p>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div class="count-box">
                            <img src='https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/available-load.png' style={{ width: "40%", marginTop: 30, margin: '0 auto' }} class="card-img-top" />
                       
                                <span data-toggle="counter-up">{countValue.postLoads}</span>
                                <p>Post Loads</p>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div class="count-box">
                            <img src='https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/available-truck.png' style={{ width: "40%", marginTop: 30, margin: '0 auto' }} class="card-img-top" />
                       
                                <span data-toggle="counter-up">{countValue.availableTruck}</span>
                                <p>Available Truck</p>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </section>
        </>
    );
}
export default CountSection;
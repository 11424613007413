import React from "react";
import globalStorage from '../global-storage';
function Slider(props) {
  return (
    <>
      <section id="Slider"  style={{ marginTop: 10 }}>
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/sliderImage/slider-1.png" alt="First slide" style={{width:'100%'}} />
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/sliderImage/slider-2.png" alt="Second slide" style={{width:'100%'}} />
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/sliderImage/slider-3.png" alt="Third slide" style={{width:'100%'}} />
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
      </section>
    </>
  );
}
export default Slider;
import React, { useState } from "react";
import axios from "axios";
import globalStorage from '../../global-storage';
import AboutLayout from "../../component/about-layout"
function RateCard(params) {
  return (
    <>
      <section id="headerImage">
        <img src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/header/read-card-min.png" style={{ width: "100%" }}></img>
      </section>
      <AboutLayout appAbout={globalStorage.appAbout.aboutRateCard} classStyle={"about-video"} />
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-md-12">
            <h1>INDIA BIGGEST DIGITAL TRANSPORT DIRECTORY</h1>
            <p style={{ textAlign: 'justify' }}>Being in such fast moving & Digital era, we can’t even imagine to carry out the Directory Book for any information we require on PAN India. The same way, it’s challenging for Transporters as well. </p>
            <p style={{ textAlign: 'justify' }}>Movers Trip, satisfying the customer needs digitally. Movers Trip facilitate its services to Transporters, Lorry Owners and who has the load to relocate from one place to another.</p>
            <p style={{ textAlign: 'justify' }}>Movers Trip providing India’s largest online Transport-Directory with Transporters Business Card that improves operational efficiencies. Transporter’s details are well organised and It’s maintained District & State wise. Transport Directory saving your precious time and furnishing the optimum output.</p>
            <p style={{ textAlign: 'justify' }}>Going forward in Transport Directory, we conveniently view the Contact Details, Business Cards, Area of Service, Type of Transporter Business and rest Business information related to various Transport Companies. We can directly make a call or WhatsApp a message to transporters by a single click on Movers Trip App.</p>
            <p style={{ textAlign: 'justify' }}>The idea behind the launching Transport-Directory is to provide a smoother way to search & find the
              transport-related information in no time.
            </p>
            <p style={{ textAlign: 'justify' }}>Movers Trip Digital Transport Directory also offers you to easily share the transporters profile to
              others via WhatsApp, Message and on any platform.
            </p>
            <p style={{ textAlign: 'justify' }}>Besides of Transport Directory Movers Trip also offering a Digital Directory for All India Packers and Movers where we can find Packers and Movers Companies Details with Business Cards, Contact Details States wise.</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default RateCard;
import React from "react";
import globalStorage from '../global-storage';
function AboutVedio(params) {
  return (
    <>
      <section id="about-video" className="about-video">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
              <img src={globalStorage.appAbout.moverstripApp.imageUrl} className="img-fluid" alt="moverstrip App Screen gif" />
            </div>
            <div className="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
              <h3>{globalStorage.appAbout.moverstripApp.title}</h3>
              <p className="font-italic">
                {globalStorage.appAbout.moverstripApp.subTitle}
              </p>
              <h2>These are the features which fulfilling your needs by making available leads for your Business. </h2>
              <ul>
                {globalStorage.appAbout.moverstripApp.item.map(item =>
                  <li><i className="bx bx-check-double"></i>{item.value}</li>
                )}
              </ul>
              <p>
                {globalStorage.appAbout.moverstripApp.details}
              </p>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}
export default AboutVedio;
import React from "react";
import userIcon from '../img/user-male.png';
const Review = [
    {
        userName:"Rajesh Sharma",
        jobPost:"Packers And Movers",
        reviewPost:"Most use full app for packers and movers business. This is connecting Packers and movers and transporters."
    },
    {
        userName:"Mukesh Soni",
        jobPost:"Transporter",
        reviewPost:"I register as a transporter moversTrip subscriber and I like this app service."
    },
    {
        userName:"Rakesh Tiwari",
        jobPost:"Packers And Movers",
        reviewPost:"I am packers and movers and I always need vehicle and load and this app full fill my requirement"
    },
    {
        userName:"Prakash Saini",
        jobPost:"Packers And Movers",
        reviewPost:"I use MoversTrip subscriber app one month ago and this app is very use full application for packers and movers"
    },
]
function Testimonials(params) {
    return (
        <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Testimonials</h2>
                    <p>Customer Review for MoversTrip</p>
                </div>
            </div>
            <div class="owl-carousel testimonials-carousel">
            {Review.map(item => (
                     <div class="testimonial-item">
                     <p>
                         <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                         {item.reviewPost}
               <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                     </p>
                     <img src={userIcon} class="testimonial-img" alt="Testimonials1" />
                     <h3> {item.userName}</h3>
                     <h4> {item.jobPost}</h4>
                 </div>
           
            ))
                }
             </div>
        </section>
    );
}
export default Testimonials;
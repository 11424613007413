import React, { useState } from "react";
import axios from "axios";
import globalStorage from '../global-storage';
import EnquiryForm from "../component/enquiry-form"
function Franchise(params) {
  return (
    <>
      <section id="headerImage">
      <img src="https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/header/f-header.jpg" style={{width:"100%"}}></img>
      </section>
      <EnquiryForm type="Franchise" title={"Request For Franchise"} subTitle={"If you join moverstrip as a Franchise. Just fill the form and earn money more"}/>
    </>
  );
}
export default Franchise;
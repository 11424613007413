import React from "react";
import globalStorage from '../global-storage';
function Service(params) {
    return(
        <>
           <section id="service" class="services section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2 style={{color:'white'}}>{globalStorage.service.title}</h2>
          <p style={{color:'white'}}>{globalStorage.service.subTitle}</p>
        </div>
        <div class="row">
         {globalStorage.service.serviceCat.map(item =>
           <div class="col-lg-4 col-md-6 d-flex align-items-stretch" style={{marginBottom:10}} data-aos="zoom-in" data-aos-delay="100">
           <div class="icon-box iconbox-blue">
             <div class="icon">
               <img src={item.imageUrl} className="card-img-top"/>
             </div>
             <h4>{item.title}</h4>
             <p>{item.discription}</p>
           </div>
         </div>
          )}
        </div>

      </div>
    </section>
        </>
    );
}
export default Service;
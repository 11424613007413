const langJSON = {
  apiUrl: "https://api.Moverstrip.com/api/",
  slider: {
    title: "Grow your transport , packers and movers business with MOVERSTRIP DIGITAL SERVICES LLP",
    subTitle: "We provide digital service for transport , packers and movers business"
  },
  appAbout: {
    moverstripApp: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/moverstrip-min.png",
      title: "MoversTrip Mobile Application",
      subTitle: "Download the MoversTrip app and create your business profile as packers , movers and transporter",
      details: "Get Estimated Freight Rate instantly through Digital Rate Card. MoversTrip connects Truck Owner to Transporter and Load Owner to Truck and Transporter. Moverstrip Mobile App Connects you to All India Transporter and Packers & Movers through its Digital Directory",
      item: [
        { value: "Available Truck List" },
        { value: "Available Load List" },
        { value: "Digital Rate Card" },
        { value: "MTGPS Services" },
        { value: "Create your own Business Profile." },
        { value: "Facility to direct contact with Container / Vehicle Owner." },
        { value: "PAN-INDIA Digital Directory for verified & authenticated Transporters." },
        { value: "PAN-INDIA Digital Directory for Packers & Movers." }
      ]
    },
    aboutTransporter: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/transport%20directory%201-min.png",
      title: "MoversTrip transporter directory",
      subTitle: "Register as a transporter in moversTrip",
      details: "MoversTrip mobile app connects the packers, movers, and transporter industry. You can get all India packers, movers, and LCV transporter digital directory and post your truck request and post-load request and send a direct message to packers and movers.",
      item: [
        { value: "Share your profile with visiting card any person" },
        { value: "Get moversTrip load and truck details in message" },
        { value: "Add your business in your city" },
      ]
    },
    aboutPackers: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/packers%20and%20movers-min.png",
      title: "MoversTrip packers and movers directory",
      subTitle: "Register as a packers and movers in moversTrip",
      details: "MoversTrip mobile app connects the packers, movers industry. You can get all India packers, movers,And post your truck request and post-load request and send a direct message to packers and movers.",
      item: [
        { value: "Share your profile with visiting card any person" },
        { value: "Get moversTrip load and truck details in message" },
        { value: "Add your business in your city" },
      ]
    },
    aboutAvailableLoad: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/share%20post%20load-min.png",
      title: "Available Load",
      subTitle: "Find your near by available load",
      details: "Find out the Available Load on the app. Just open the app, click on the Available Load button and Get the Available Load List from all over the India ",
      item: [
        { value: "Find Truck Load from all over the India and get the suitable load for your Trucks." },
        { value: "Directly make a call or WhatsApp to Load Owners and Book the load." },
        { value: "Share the load details on WhatsApp and desired platforms." },
        { value: "Directly be in touch with Transporter and Packers & Movers as well." },
      ]
    },
    aboutAvailableTruck: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/share%20post%20load-min.png",
      title: "Available Truck",
      subTitle: "Find your near by available load",
      details: "Find out the Available Truck on the app. Just open the app, click on the Available Truck button and Get the Available Truck List from all over the India",
      item: [
        { value: "Find the right Truck for your Transport Business." },
        { value: "Book Trucks for all over the India in a single click on Movers Trip App" },
        { value: "Directly make a call or WhatsApp to Lorry Owner and Book the Lorry instantly." },
        { value: "Share the Truck details on WhatsApp and desired platforms." }
      ]
    },
    aboutRateCard: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/share%20post%20load-min.png",
      title: "Rate card",
      subTitle: "Calculate truck rate according",
      details: "We can get easily Estimated Charges for different types of Vehicle Transportations for different cities in India. ",
      item: [
        { value: "Submit the type of vehicle" },
        { value: "Submit Origin & Destination Location" },
      ]
    },
    aboutGpsService: {
      imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/gps%20mob-min.png",
      title: "GPS",
      subTitle: "Protect and Track your Truck with the India’s economical GPS service at utmost negligible cost.",
      details: "Get the remarkable features in GPS as follow:",
      item: [
        { value: "Vehicles Status i.e. offline, online, running, stopping etc." },
        { value: "Tracking - track your vehicle on the map, navigate it, get the current location of your vehicle, get the movement and stoppage records of vehicle. " },
        { value: "Truck Report- get your vehicle report hourly, Average speed, Stoppage and movement report filter by day-wise." },
        { value: "Engine on/off- get the control of your vehicle in your hand. Switch the vehicle engine On and Off whenever you want. Control over on the vehicle when it is far away from you." },
      ]
    }
  },
  App: {
    moverstripApp: {
      title: "Benefits of the MoversTrip app",
      item: [
        { value: "You can post the loads" },
        { value: "Available load list" },
        { value: "Available truck list" },
        { value: "Create your business profile" },
        { value: "Facility to direct contact with container/vehicle owner." },
        { value: "All India directory of verified LCV transporters" },
        { value: "All India directory of verified packers & movers" }
      ]
    },
  },
  appurl: {
    moverstripAppUrl: "https://play.google.com/store/apps/details?id=com.moverstrip.app01&hl=en_IN&gl=US",
    mtGpsUrl: "https://play.google.com/store/apps/details?id=com.moverstrip.digital.prod&hl=en&gl=US"
  },
  aboutPage: {
    title: "About us",
    subTitle: "MOVERSTRIP DIGITAL SERVICES LLP",
    aboutDetails: "MOVERSTRIP DIGITAL SERVICES LLP is newly fast growing online platform for Transportation Business. MoversTrip is a name of much efforts and dedication towards facilitate the smoothness for Truck Owners & Transport Business. We are bringing a revolution in the Transport Industry and creating a platform where Truck Owner can get the Load & Transporter can get the Truck easily. It is so simple like that.",
  },
  contactUs: {
    title: "Contact us",
    subTitle: "MOVERSTRIP DIGITAL SERVICES LLP provides an online platform to their subscriber to find all types of open & closed body container vehicles from all over India.",
    companyName: "MOVERSTRIP DIGITAL SERVICES LLP",
    headOfficeAddress: "Tirupati Estate, Nr. AM way bridge, NH No 8, Ahmedabad - 382427. Gujarat.",
    registerAddress: "1st Floor 3K10A Housing Board Dhola Bhata, Ajmer - 305001. Rajasthan.",
    operationalPerson:" 3rd Floor, Shree Krishna Tower,Adarsh Nagar, Ajmer, Rajasthan – 305002 INDIA",
    email: "info@moverstrip.com",
    supportNumber: "7300006595",
    helpNumber: "9352777744",
    mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14291.791504671692!2d74.6501466!3d26.4251586!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9581ae79ab641d3d!2sMoverstrip.com!5e0!3m2!1sen!2sin!4v1620373621825!5m2!1sen!2sin"
  },
  socialUrl: {
    twitterUrl: "https://twitter.com/Moverstrip",
    facebookUrl: "https://www.facebook.com/Moverstrip-101456641459205/",
    instagramUrl: "https://www.instagram.com/Moverstrip/?hl=en",
    linkedinUrl: "https://www.linkedin.com/in/movers-trip-8746191a3/",
  },
  service: {
    title: "Services",
    subTitle: "MoversTrip Services",
    serviceCat: [
      {
        title: "Post Load",
        discription: "Moverstrip users can post their load then the MoversTrip app will deliver your message to all the transporter and vehicle owners of that city so they can directly contact you.",
        imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/box.png"
      },
      {
        title: "Load Request",
        discription: "MT GPS users can post the needed load then the Moverstrip app will deliver this message to all the users so they can  directly contact you.",
        imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/need-load.png"
      },
      {
        title: "Available Truck",
        discription: "MoversTrip users can check the available truck in available truck list and direct contact them. ",
        imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/available-truck.png"
      },
      {
        title: "Available Load",
        discription: "MoversTrip & MT GPS users can check the avaialble load in the avaialble load list.",
        imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/available-load.png"
      },
      {
        title: "Transport Directory",
        discription: "MoversTrip & MT GPS users can check all India LCV transporters and packers and movers directory with visiting cards and directly contact them.",
        imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/trasnporter.png"
      },
      {
        title: "GPS",
        discription: "MoversTrip digital service is providing MT GPS Tracker for LCV trucks.",
        imageUrl: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/homeIcon/gps.png"
      },
    ]
  },
  appScreen: {
    title: "Moverstrip and MT GPS Tracker",
    subTitle: "Make your business easy and fast"
  },
  mtEnquiry: {
    title: "Packers and Movers Enquiry System",
    subTitle: "Manage your enquiry",
    contactNumber: "9116633119",
    whatsappNumber: "9116633119",
  },
  enquiryForm: {
    title: "Enquiry form",
    subTitle: "Fill enquiry form for demo account for packers and movers enquiry system",
  },
  successEnquiry: {
    successImage: "https://Moverstrip-dev.s3.us-east-1.amazonaws.com/system/right-success.png",
    title: "Send your request successfully",
    subTitle: "Thank you for a request for the packers and movers enquiry system. We will contact you very soon",
    labelUrl: "Url Link",
    valueUrl: "Enquiry demo url link",
    demoUrl: "http://packers-demo.Moverstrip.com/",
    usersname: "demo@Enquiry",
    password: "123456"
  },
  priceGps: {
    gpsPlan: {
      title: "GPS Price",
      titleParagraph: "Get the best price for GPS in India",
      colSize: "col-md-12 aos-init aos-animate",
      btnTitle: "Send Request",
      btnLink: "/send-request",
      planList: [
        {
          planTitle: "Yearly Plan",
          price: 2388,
          discount: 0,
          planType: "Yearly",
          item: [
            { value: "Location Tracking" },
            { value: "Route Navigation" },
            { value: "Self Report Generation" },
            { value: "Engine On/Off Facilities" },
            { value: "Truck Details sharing to Transporter in seconds" },
            { value: "All India Transport Directory" },
            { value: "All India Packers & Movers Directory" },
            { value: "Available Load List" },
            { value: "Available Truck List" },
            { value: "Digital Rate Card" },
            { value: "MoversTrip Verified and Authenticated Customers" },
          ]
        }
      ]
    }
  },
  renewGpsPlan: {
    gpsPlan: {
      title: "GPS Renewal Plan",
      titleParagraph: "Get the best price for GPS in India",
      colSize: "col-md-3 aos-init aos-animate",
      btnTitle: "Send Request",
      btnLink: "/send-request",
      planList: [
        {
          planTitle: "Monthly Renewal Subscription",
          price: 199,
          discount: 0,
          planType: "Monthly",
          item: [
            { value: "Location Tracking" },
            { value: "Route Navigation" },
            { value: "Salf Report Generation" },
            { value: "Engine On/Off Facilities" },
            { value: "Truck Details sharing to Transporter in seconds" },
            { value: "All India Transport Directory" },
            { value: "All India Packers & Movers Directory" },
            { value: "Available Load List" },
            { value: "Available Truck List" },
            { value: "Digital Rate Card" },
            { value: "MoversTrip Verified and Authenticated Customers" },
            
          ]
        },
        {
          planTitle: "Quarterly Renewal Subscription",
          price: 597,
          discount: 10,
          planType: "Quarterly",
          item: [
            { value: "Location Tracking" },
            { value: "Route Navigation" },
            { value: "Salf Report Generation" },
            { value: "Engine On/Off Facilities" },
            { value: "Truck Details sharing to Transporter in seconds" },
            { value: "All India Transport Directory" },
            { value: "All India Packers & Movers Directory" },
            { value: "Available Load List" },
            { value: "Available Truck List" },
            { value: "Digital Rate Card" },
            { value: "MoversTrip Verified and Authenticated Customers" },
            
          ]
        },
        {
          planTitle: "Half Yearly Renewal Subscription",
          price: 1194,
          discount: 20,
          planType: "Half Yearly",
          item: [
            { value: "Location Tracking" },
            { value: "Route Navigation" },
            { value: "Salf Report Generation" },
            { value: "Engine On/Off Facilities" },
            { value: "Truck Details sharing to Transporter in seconds" },
            { value: "All India Transport Directory" },
            { value: "All India Packers & Movers Directory" },
            { value: "Available Load List" },
            { value: "Available Truck List" },
            { value: "Digital Rate Card" },
            { value: "MoversTrip Verified and Authenticated Customers" },
            
          ]
        },
        {
          planTitle: "Annual Renewal Subscription",
          price: 2388,
          discount: 30,
          planType: "Yearly",
          item: [
            { value: "Location Tracking" },
            { value: "Route Navigation" },
            { value: "Salf Report Generation" },
            { value: "Engine On/Off Facilities" },
            { value: "Truck Details sharing to Transporter in seconds" },
            { value: "All India Transport Directory" },
            { value: "All India Packers & Movers Directory" },
            { value: "Available Load List" },
            { value: "Available Truck List" },
            { value: "Digital Rate Card" },
            { value: "MoversTrip Verified and Authenticated Customers" },
            
          ]
        }
      ]
    }
  },
  priceSubscription: {
    gpsPlan: {
      title: "Subscription Plan for MoversTrip",
      titleParagraph: "MoversTrip has has two types of subscription plan, One is for MT-GPS Subscriber and another one is for TRANS-LOAD Subscriber. These are attractive Product & Services for Transporters, Lorry Owners & Load Owners.",
      colSize: "col-md-3 aos-init aos-animate",
      btnTitle: "Download Now",
      btnLink: "https://play.google.com/store/apps/details?id=com.moverstrip.app01&hl=en_IN&gl=US",
      planList: [
        {
          planTitle: "Monthly subscription",
          price: 99,
          discount: 0,
          planType: "Monthly",
          item: [
            { value: "Transport Directory" },
            { value: "Rate Card" },
            { value: "Available Truck" },
            { value: "Post Load" },
            { value: "Load Request" },
          ]
        },
        {
          planTitle: "Quarterly subscription",
          price: 267,
          discount: 0,
          planType: "Quarterly",
          item: [
            { value: "Transport Directory" },
            { value: "Rate Card" },
            { value: "Available Truck" },
            { value: "Post Load" },
            { value: "Load Request" },
          ]
        },
        {
          planTitle: "Half Yearly subscription",
          price: 475,
          discount: 0,
          planType: "Half Yearly",
          item: [
            { value: "Transport Directory" },
            { value: "Rate Card" },
            { value: "Available Truck" },
            { value: "Post Load" },
            { value: "Load Request" },
          ]
        },
        {
          planTitle: "Annual subscription",
          price: 832,
          discount: 0,
          planType: "Annually",
          item: [
            { value: "Transport Directory" },
            { value: "Rate Card" },
            { value: "Available Truck" },
            { value: "Post Load" },
            { value: "Load Request" },
          ]
        }
      ]
    }
  },
  moverstripFetures: {
    title: "Serve with Happiness",
    subTitle: "Have a look on MoversTrip Services",
    serviceCat: [
      {
        title: "Available Truck",
        discription: "MoversTrip users can post their load then the MoversTrip app will deliver your message to all the transporter and vehicle owners of that city so they can directly contact you.",
        Image: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/icon/truck.png"
      },
      {
        title: "Available Load",
        discription: "MT GPS users can post the needed load then the MoversTrip app will deliver this message to all the users so they can  directly contact you.",
        Image: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/icon/shipment.png"
      },
      {
        title: "Transporter Directory",
        discription: "MoversTrip & MT GPS users can check the avaialble load in the avaialble load list.",
        Image: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/icon/transportation.png"
      },
      {
        title: "Packers & Movers Directory",
        discription: "MoversTrip & MT GPS users can check all India LCV transporters and packers and movers directory with visiting cards and directly contact them.",
        Image: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/websiteStock/icon/cargo-mover.png"
      },
    ]
  },
  youTubeSection: {
    title: "MOVERSTRIP MEDIA",
    item: [
      { url: "https://www.youtube.com/embed/3IBMiT8_-AE" },
      { url: "https://www.youtube.com/embed/HnrewirYRhA" },
      { url: "https://www.youtube.com/embed/E4PIAgNP1k8" },
      { url: "https://www.youtube.com/embed/oJbGu_2T6Ic" },
    ]
  },
  transporterYoutube: {
    title: "Transport Digital Directory",
    item: [
      { url: "https://www.youtube.com/embed/oJbGu_2T6Ic" },
    ]
  }
};
export default langJSON;